<template>
  <div class="talk-about">
    <div class="header">
      <div class="header-placeholder">
        <div class="header-inner">
          <img class="logo" :src="logo" alt="logo" />
        </div>
      </div>
    </div>
    <div class="about-content">
      <el-tabs :tab-position="tab" :value="cardname" @tab-click="clickTabs">
        <el-tab-pane label="联系我们" name="us">
          <div class="contact-us">
            <p @click="seePrivacy">公司名称：上海嵩恒网络科技股份有限公司</p>
            <p @click="seeUser">
              公司地址：上海市浦东新区银冬路491号（集创公园）2号楼5楼
            </p>
            <p>联系邮箱：tantao@021.com</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="用户协议" name="user">
          <div class="user-agreement">
            <p class="sub-title bold fs16 no-indent">1、导言</p>
            <p>
              欢迎您签署本《探讨用户协议》（下称“本协议”）使用“探讨”软件及相关服务！
            </p>
            <p>
              探讨”软件及相关服务，系指公司通过合法拥有并运营的、标注名称为“探讨”的客户端应用程序以及“探讨网”（tantao.com）的网站，向您提供的产品与服务，包括但不限于个性化推荐、发布信息、互动交流等核心功能以及其他功能，为创作与交流的平台。《探讨用户协议》是您与公司之间就您注册、登录、使用（以下统称“使用”）“探讨”软件及相关服务所订立的协议。
            </p>
            <p>
              为了更好地为您提供服务，请您在开始使用“探讨”软件及相关服务之前，认真阅读并充分理解以下全部内容，特别是涉及免除或者限制责任的条款、个人信息使用的条款和争议解决条款等。<span
                class="bold"
                >其中，免除或者限制责任条款，以及其他涉及您重大权益的条款将以加粗形式提示您重点注意，您应重点阅读。如您未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用“探讨”软件及相关服务。</span
              >
            </p>
            <p>
              本协议是您与公司至今关于注册以及使用公司提供的相关产品和服务所订立的协议。<span
                class="bold"
                >如您自主通过选择下载、安装、登录等方式同意并使用“探讨”软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与“探讨”软件及相关服务相关的协议和规则（包括但不限于《隐私政策》）的约束。</span
              >如阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
            </p>
            <p class="sub-title bold no-indent fs16">
              2、 “探讨”软件及相关服务
            </p>
            <p>
              2.1
              您使用“探讨”软件及相关服务，可以通过东方资讯网站首页位置获取“探讨”客户端应用程序或访问探讨网（tantao.com）相关网站。若您并非从公司或经公司授权的第三方获取本软件的，公司无法保证非官方版本的“探讨”软件能够正常使用，您因此遭受的损失与公司无关。
            </p>
            <p>
              2.2
              公司可能为不同的终端设备开发不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。如您不再使用“探讨”软件及相关服务，您也可自行卸载相应的应用程序软件。
            </p>
            <p>
              2.3
              为更好的提升用户体验及服务，公司将不定期提供“探讨”软件及相关服务的更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等），您可根据需要自行选择是否更新相应的版本。
            </p>
            <p>
              2.4
              除非得到公司事先书面授权，您不得以任何形式对“探讨”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
            </p>
            <p>
              2.5
              您理解，使用“探讨”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦您在终端设备中打开“探讨”软件或访问“探讨网”的网站，即视为您使用“探讨”软件及相关服务。为充分实现“探讨”的全部功能，您可能需要将终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。
            </p>
            <p>
              2.6
              您无需注册也可开始使用“探讨”软件及相关服务，但部分功能或服务可能会受到影响。同时，您也理解，为使您更好地使用“探讨”软件及相关服务，保障您的帐号安全，某些功能和/或某些单项服务项目（如热门投票评论、点赞服务等）将要求您按照国家相关法律法规的规定，提供手机号注册登录后方可使用。
            </p>
            <p class="sub-title bold no-indent fs16">3、关于“帐号”</p>
            <p>
              3.1
              “探讨”软件及相关服务为您提供了登录通道，您有权以手机号作为自己的帐号，并获取手机验证码，未注册手机验证后自动登录；登录后您可选择自行设置符合要求的密码。用户设置的帐号、密码是用户用以登录并使用“探讨”软件及相关服务的凭证。
            </p>
            <p>
              3.2
              您理解并承诺，您所设置的帐号不得违反国家法律法规及公司的相关规则，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为，您的帐号名称、头像和等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人手机号、姓名、名称、头像等足以让人引起混淆的方式）开设帐号，不得恶意注册“探讨”帐号（包括但不限于使用他人手机号注册等行为）。公司有权对您提交的信息进行审核。
            </p>
            <p>
              3.3
              您的帐号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果公司发现或者有合理理由认为使用者并非帐号初始注册人，为保障帐号安全，公司有权立即暂停或终止向该注册帐号提供服务，或注销该帐号。
            </p>
            <p>
              3.4
              您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任，包括但不限于您在“探讨”软件及相关服务上进行的任何数据修改、言论发表等操作行为可能引起的一切法律责任。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知公司。
            </p>
            <p>
              3.5
              在丢失帐号或遗忘密码时，您可遵照公司的提供的途径及时找回密码。您理解并认可，公司特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。如因您保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失，您将自行承担相应责任。
            </p>
            <p>
              3.6
              在注册、使用和管理帐号时，您应保证注册帐号时填写的身份信息的真实性，请您在注册、管理帐号时使用真实、准确、合法的相关身份证明材料及必要信息。依照国家相关法律法规的规定，为使用“探讨”软件及相关服务的部分功能，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不规范或不符合要求的，则公司有权拒绝为您提供相关功能，您可能无法使用“探讨”软件及相关服务或在使用过程中部分功能受到限制。
            </p>
            <p>
              3.7
              除自行注册“探讨”帐号外，用户也可授权使用其合法拥有的包括但不限于公司和/或其关联公司其他软件用户帐号，以及实名注册的第三方软件用户帐号登录使用“探讨”软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当用户以前述已有帐号登录使用的，应保证相应帐号已进行实名注册登记，并同样适用本协议中的相关条款。
            </p>
            <p>
              3.8
              您理解并同意，除您登录、使用“探讨”软件及相关服务外，您还可以用“探讨”帐号登录使用公司及其关联公司提供的其他软件、服务（如：该账号系统已与“明珠号”打通可直接登录）。您以“探讨”帐号登录并使用“明珠号”服务时，将受到“明珠号”服务提供方自身的《用户协议》及其他协议条款约束。
            </p>
            <p>
              3.9
              当您完成“探讨”的帐号注册、登录并进行合理和必要的身份验证后，您可随时浏览、修改自己提交的个人身份信息。您理解并同意，出于安全性和身份识别（如帐号或密码找回申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
            </p>
            <p class="sub-title bold no-indent fs16">4、隐私政策</p>
            <p>
              公司非常重视对您隐私的保护。关于您的身份资料和其他特定资料依《隐私政策》的相关规定受到保护与规范。在您使用“探讨”软件及相关服务的过程中，您可能需要提供您的个人信息，更多关于用户个人信息保护的内容，请参见<a
                href="/talk/about?type=privacy"
                target="_blank"
                >《隐私政策》</a
              >。
            </p>
            <p class="sub-title bold no-indent fs16">5、 用户行为规范</p>
            <p>
              5.1 用户行为要求
            </p>
            <p>
              您应当对您使用本软件及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用“探讨”软件及相关服务不得进行如行为：
            </p>
            <p class="bold need-underline">
              5.1.1 盗用、冒充、利用他人手机号进行注册、登录；
            </p>
            <p class="bold need-underline">
              5.1.2
              提交、发布虚假信息，或者诱导其他用户关注、点击无关链接地址或信息；
            </p>
            <p class="bold need-underline">
              5.1.3
              恶意注册“探讨”软件及相关服务的帐号，包括但使用他人手机号注册帐号；
            </p>
            <p class="bold need-underline">
              5.1.4
              违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。
            </p>
            <p>
              5.1.5
              如果公司有理由认为您的行为违反或可能违反上述约定的，公司可独立进行判断并处理，且有权在不事先通知的情况下终止向您提供服务，并追究相关法律责任。
            </p>
            <p>
              5.2 信息内容规范
            </p>
            <p>
              5.2.1
              您按规定完成实名认证后，可以以注册帐号或“探讨”合作平台帐号登录“探讨”发表评论等。
            </p>
            <p>
              5.2.2
              公司致力使资讯信息、用户评论成为文明、理性、无害的交流。在推动发布信息、互动交流、评论业务发展的同时，不断加强相应的信息安全管理能力，完善发布信息、跟帖评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。
            </p>
            <p class="bold need-underline">
              5.2.3
              您评论、发布、传播的内容应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求，并确保您所发布的信息不包含以下【禁止性信息】内容：
            </p>
            <p class="bold need-underline">
              （一）违反国家法律法规禁止性规定的；
            </p>
            <p class="bold need-underline">
              （二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
            </p>
            <p class="bold need-underline">
              （三）欺诈、虚假、不准确或存在误导性的；
            </p>
            <p class="bold need-underline">
              （四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
            </p>
            <p class="bold need-underline">
              （五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
            </p>
            <p class="bold need-underline">
              （六）存在可能破坏、篡改、删除、影响探讨平台任何系统正常运行或未经授权秘密获取探讨平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
            </p>
            <p class="bold need-underline">
              （七）其他违背社会公共利益或公共道德或依据相关探讨平台协议、规则的规定不适合在探讨平台上发布的。
            </p>
            <p class="bold no-indent fs16">6、“探讨”信息内容的使用规范</p>
            <p>
              6.1
              您在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表公司的观点、立场或政策，公司对此不承担任何责任。
            </p>
            <p>
              6.2
              未经公司书面许可，您不得自行或授权、协助任何第三人、第三方软件对本协议“探讨”软件及相关服务中信息内容进行如下行为：
            </p>
            <p>
              （1）复制、读取、摘抄“探讨”软件及相关服务的信息内容，用于包括但不限于宣传、销售、引用发布等商业用途；
            </p>
            <p>
              （2）擅自翻译、翻译、注释、汇编“探讨”软件及相关服务的信息内容后在“探讨”软件及服务页面以外的渠道进行再创作展示；
            </p>
            <p>
              （3）其他未经公司书面许可而非法获取或使用“探讨”软件及相关服务的信息内容的行为。
            </p>
            <p class="bold no-indent fs16">
              7、违约处理
            </p>
            <p class="bold need-underline">
              7.1
              您须为自己帐号下的一切行为承担法律责任，包括用户所发表的任何内容以及其它人使用您的帐号而产生的任何后果。如您或使用您帐号的其他人利用公司的服务侵害他人民事权益的，您应当承担侵权等法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在公司首先承担了因您行为导致的行政处罚或侵权损害赔偿责任后，您应给予公司等额的赔偿。
            </p>
            <p class="bold need-underline">
              7.2针对您违反本协议或其他服务条款的行为，公司有权依据实际情况采取拒绝发布、屏蔽或删除发言、限期禁止发言、终止向您提供服务功能等措施。公司有权自行处理，并根据实际情况决定是否恢复相关帐号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。
            </p>
            <p class="bold need-underline">
              7.2
              因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担全部可能由此引起的法律责任。因您的违法、侵权或违约等行为导致公司及其关联公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联公司因此遭受的全部损失（包括但不限于公司的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失）。
            </p>
            <p class="bold no-indent fs16">
              8、服务的变更、中止、取消或终止
            </p>
            <p>
              8.1
              您理解并同意，公司可根据国家最新的法律法规及产品或服务的自身维护等需要，不时优化和改进服务，期间公司可能变更、中止、取消或终止产品或服务的某项功能。
            </p>
            <p>
              如您对此不同意的，您应当于变更事项确定的生效之日起停止使用公司产品或服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用公司产品或服务，则视为您同意已生效的变更事项。
            </p>
            <p>
              8.2
              您理解，若因国家行政机关或司法机关发布的生效法律法规的规定确认用户存在违法或侵权行为，或公司自身判断，认为您的行为涉嫌违反本服务协议或其他公司规则的，则公司有权中止或终止向您继续提供服务，并不承担任何责任。
            </p>
            <p class="bold no-indent fs16">9、知识产权</p>
            <p>
              9.1
              公司提供在“探讨”软件及相关服务中涉及到的产品的所有内容的所有权以及相关软件（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、数据资料、版面设计、账号等）的知识产权归属公司所有。公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于复制、销售、改编、传播）“探讨”软件及相关服务中的内容。
            </p>
            <p>
              9.2
              您理解并同意，在使用“探讨”软件及相关服务时发布上传的文字、图片、视频、音频等均由您合法享有或已获合法授权。您通过“探讨”上传、发布的任何内容的知识产权归属您或原始著作权人所有。
            </p>
            <p>
              9.3
              您知悉、理解并同意，为持续改善并为您提供更好的服务，您通过“探讨”软件及相关服务上传发布、传输或传播的内容（包括但不限于文字、图片、图像、音频、视频和/或音频中的音乐、声音、对话等），授权公司及其关联公司、控制公司、继承公司一项全球范围内、免费、非独家、可再许可（通过多层次）的权利（包括但不限于复制权、信息网络传播权、改编权、汇编权、修改权、翻译权、制作衍生品、表演和展示等权利），使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等。您在此确认并同意，上述权利的授予包括在内容、“探讨”/“探讨网”、公司和/或公司品牌有关的任何的宣传、推广、广告和/或相关营销中使用和以其他方式开发内容（全部或部分）的权利和许可。为避免疑惑，您同意，上述权利的授权包括许可使用、复制、展示、传播您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识、公司标记及其他物料、素材等。
            </p>
            <p>
              9.4
              您确认并同意授权公司以公司自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：发送维权函、提起诉讼或仲裁、调解、和解等，公司有权对维权事宜做出决策并独立实施。
            </p>
            <p>
              9.5
              公司为“探讨”开发、运营提供技术支持，并对“探讨”软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。
            </p>
            <p class="bold no-indent fs16">10、未成年人保护条款</p>
            <p class="bold need-underline">
              10.1
              若您是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下认真阅读本协议后，方可使用“探讨”软件及相关服务。
            </p>
            <p class="bold need-underline">
              10.2
              未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能引起的全部法律责任。
            </p>
            <p class="bold need-underline">
              10.3
              青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。
            </p>
            <p class="bold no-indent fs16">11、法律适用、管辖与其他</p>
            <p class="bold">
              11.1
              本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="隐私政策" name="privacy">
          <div class="user-privacy">
            <p class="need-indent">
              本政策仅适用于上海嵩恒网络科技股份有限公司的<span class="bold"
                >探讨</span
              >产品或服务。最近更新日期：2021年3月25日
            </p>
            <p class="need-indent">
              欢迎您使用我们的产品和服务！我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。为此，探讨服务提供者（或称<span
                class="bold"
                >“我们”</span
              >）制定本《隐私权政策》（下称<span class="bold">“本政策”</span
              >）以便您充分了解，在您使用探讨的产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息，以及您可以如何管理您的个人信息。
            </p>
            <p class="need-indent">
              在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以<span
                class="bold need-underline"
                >粗体/粗体</span
              >下划线标识的条款，确保您充分理解和同意后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。
            </p>
            <p class="sub-title need-indent">
              本政策将帮助您了解以下内容：
            </p>
            <p class="sub-title need-indent">
              一、我们如何收集和使用您的用户信息
            </p>
            <p class="sub-title need-indent">
              二、我们如何使用 Cookie 或同类技术
            </p>
            <p class="sub-title need-indent">
              三、我们如何共享、转让、公开披露您的用户信息
            </p>
            <p class="sub-title need-indent">四、我们如何保护您的用户信息</p>
            <p class="sub-title need-indent">五、您的权利</p>
            <p class="sub-title need-indent">六、我们如何处理儿童的个人信息</p>
            <p class="sub-title need-indent">
              七、您的用户信息如何储存及如何在全球范围转移
            </p>
            <p class="sub-title need-indent">八、本政策如何更新</p>
            <p class="sub-title need-indent">九、如何联系我们</p>
            <p class="sub-title need-indent fs16">
              一、我们如何收集和使用您的用户信息
            </p>
            <p class="need-indent">
              （一）您使用我司产品或服务过程中我们收集和使用的信息
            </p>
            <p class="need-indent">
              我们仅会出于本政策所述的业务功能，收集和使用您的用户信息，收集用户信息的目的在于向您提供产品或服务，您有权自行选择是否提供该信息，而这些信息有助于我们更好地为您使用产品或服务：
            </p>
            <p class="need-indent">
              在您使用我们的服务时，允许我们收集您自行向我们提供的或为向您提供服务的信息包括：
            </p>
            <p>
              1、您提供的信息。比如：
            </p>
            <p>
              （1）您在注册探讨产品或服务的帐号或使用探讨产品或服务时，向我们提供的信息；
            </p>
            <p>
              （2）您通过探讨服务向第三方提供的共享信息，以及您使用探讨服务时所存储的信息；
            </p>
            <p>
              （3）对于我们收集的用户信息，我们将用于为您展示和推送新闻等。
            </p>
            <p>
              2、第三方共享您的信息。亦即第三方使用探讨服务时所提供有关您的共享信息。
            </p>
            <p>（二）我们会出于以下目的，收集和使用您以下类型的个人信息：</p>
            <p class="bold">
              我们需要特别提醒您的是：由于我们的产品和服务可能会不定期更新，为您提供的内容也不同，因此相关业务功能（包括其收集的您的个人信息类型）也会因您选择的产品/服务的内容不同而有所区别，具体请您以实际使用的产品/服务为准。如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。
            </p>
            <p class="bold">
              1、帮助您完成注册及登录
            </p>
            <p>
              便于我们为您提供持续稳定的服务并保障您使用探讨服务的安全性，我们需要您提供基本注册或登录信息，包括手机号码，并创建您的帐号、用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本功能，您不需要注册成为探讨用户及提供上述信息。
            </p>
            <p>
              您也可以在注册、登录或后续使用过程中，填写或补充您的其他额外信息（例如您的昵称、头像、性别），这将有助于我们给您提供个性化和更优的服务体验，但如果您不提供这些信息，并不会影响您使用探讨服务的基本功能。
            </p>
            <p class="bold">
              2、维护基本功能的正常运行
            </p>
            <p>
              在您使用我们服务过程中，为向您提供浏览、搜索等基本功能，并识别帐号异常状态、了解产品适配性、保障探讨服务的网络及运营安全，以维护前述基本功能的正常运行，<span>
                我们可能会直接或者间接地收集、存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
              </span>
            </p>
            <p class="bold">
              （1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如:您的登录帐号（手机号）、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、刷新记录、发布记录、关注、订阅、收藏、点赞及分享。
            </p>
            <p class="bold">
              （2）设备信息：我们可能会接收并记录您所使用的设备相关信息。例如：操作系统及版本、设备分辨率、设备设置、进程及软件列表、软硬件特征信息、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用探讨服务。
            </p>
            <p>
              请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。
            </p>
            <p class="bold">
              3、向您提供互动与发布服务
            </p>
            <p>
              您在使用探讨服务时、上传和/或发布信息以及进行相关互动行为（例如发布评论、回复评论、）时，我们将收集您上传、发布或形成的信息，并使用您的昵称、头像和发布内容以作展示。
            </p>
            <p class="bold">
              请注意，您公开发布的信息中可能包含您的个人信息或个人敏感信息，请您在发布前慎重考虑。如您发布的信息涉及到他人的个人信息的，您应当在发布前征得他人同意。
            </p>
            <p class="bold">4、为您提供客服或其他用户响应功能</p>
            <p>
              当您联系我们的客服或使用其他用户响应功能时（例如提出售中售后申请、个人信息保护投诉或建议、其他客户投诉和需求），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的帐号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的<span
                class="bold need-underline"
                >其他必要信息</span
              >，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
            </p>
            <p class="bold">5、向您提供商品或服务的信息展示和推送</p>
            <p>
              基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（例如您的浏览及搜索记录、设备信息），我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，用于提取您的浏览、搜索偏好、行为习惯等相关特征，以便向您提供更契合您需求的页面展示和推荐个性化内容。帮助我们改善探讨服务以及提供或推广我们/第三方的如下商品和服务：
            </p>
            <p class="bold">6、为您提供安全保障</p>
            <p>
              为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或淘宝网相关协议、规则的情况，我们在应用程序中嵌入我们关联公司开发的应用安全SDK收集您的设备信息、服务日志信息，并可能使用或整合您的账户信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </p>
            <p class="bold">7、有关个人敏感信息的提示</p>
            <p class="bold">
              <span class="bold need-underline">个人敏感信息</span
              >，是指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息。请您谨慎并留意个人敏感信息，您同意我们可以按本政策所述的目的和方式来处理您的个人敏感信息。
            </p>
            <p>
              您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您停止使用推送服务时，我们将停止使用并删除上述信息。
            </p>
            <p class="bold sub-title">
              （三）征得授权同意的例外
            </p>
            <p>
              请您理解，根据法律法规及相关国家标准，以下情形中，我们收集和使用您的用户信息无需征得您的授权同意：
            </p>

            <p>1、与国家安全、国防安全直接相关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p>
              4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>5、所收集的您的用户信息是您自行向社会公众公开的；</p>
            <p>
              6、从合法公开披露的信息中收集的您的用户信息，如合法的新闻报道、政府信息公开等渠道；
            </p>
            <p>7、根据您的要求签订或履行合同所必需的；</p>
            <p>
              8、用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
            </p>
            <p>
              9、 个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的；
            </p>
            <p>
              10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
            </p>
            <p>11、法律法规规定的其他情形。</p>
            <p class="bold">
              二、我们如何使用 Cookie 或同类技术
            </p>
            <p class="sub-title">（一）Cookie</p>
            <p>
              为确保网站正常运转，我们可能会在您的计算机或移动设备上存储一个或多个名为
              Cookie 的小数据文件。Cookie
              通常包含标识符、站点名称以及一些号码和字符。借助于
              Cookie，网站能够存储您的访问偏好数据。
            </p>
            <p>
              我们不会将 Cookie
              用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
              Cookie。您可以清除计算机上保存的所有
              Cookie，大部分网络浏览器都设有阻止Cookie
              的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
            </p>
            <p class="sub-title">
              （二）Do Not Track（请勿追踪）
            </p>
            <p>
              很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not
              Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
              Do Not
              Track，不希望自己的活动以这种方式被追踪，那么我们的所有网站都会尊重您的选择。
            </p>
            <p class="sub-title bold">
              三、我们如何共享、转让、公开披露您的用户信息
            </p>
            <p class="sub-title">（一）共享</p>
            <p>
              我们不会与其他的任何公司、组织和个人分享您的用户信息，但以下情况除外：
            </p>
            <p>
              1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。
            </p>
            <p>
              2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的用户信息。
            </p>
            <p>
              3、与我们的关联公司共享：您的用户信息可能会与我们的关联公司共享。我们只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。关联公司如要改变用户信息的处理目的，将再次征求您的授权同意。
            </p>
            <p>
              对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
            </p>
            <p class="sub-title">
              （二）转让
            </p>
            <p>
              我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：
            </p>
            <p>
              1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；
            </p>
            <p>
              2、在涉及合并、收购、资产转让或类似的交易，而您的信息可能作为此类交易的一部分而被转移。如涉及到用户信息转让，我们会再要求新的持有您用户信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
            </p>
            <p class="sub-title">（三）公开披露</p>
            <p>我们仅会在以下情况下，公开披露您的用户信息：</p>
            <p>1、获得您明确同意后；</p>
            <p>
              2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的用户信息。
            </p>
            <p class="sub-title">
              （四）共享、转让、公开披露信息时事先征得授权同意的例外
            </p>
            <p>
              请您理解，根据法律法规及相关国家标准，以下情形中，我们共享、转让、公开披露您的用户信息无需征得您的授权同意：
            </p>
            <p>1、与国家安全、国防安全直接相关的；</p>
            <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
            <p>
              4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>5、您自行向社会公众公开的信息；</p>
            <p>
              6、从合法公开披露的信息中收集的，如合法的新闻报道、政府信息公开等渠道。
            </p>
            <p class="sub-title">四、我们如何保护您的用户信息</p>
            <p>
              （一）我们已使用符合业界标准的安全防护措施保护您的用户信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的用户信息。例如，我们会部署访问控制机制，确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
            </p>
            <p>
              （二）我们的数据安全能力：{加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。为应对个人信息泄露、损毁和丢失等可能出现的风险，我们建立了专门的应急响应团队，按照公司有关安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、制定补救措施、联合相关部门进行溯源和打击。同时我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            </p>
            <p>
              尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
            </p>
            <p>
              我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，依然有可能在我们控制之外的其他环节而出现安全问题。
            </p>
            <p>
              （三）我们会采取一切合理可行的措施，确保不收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需要延长保留期或受到法律的允许。
            </p>
            <p>
              （四）互联网环境并非百分之百安全，我们将尽力确保您提供给我们的任何信息的安全性。即使我们做出了很大努力，采取了一切合理且必要的措施，仍然有可能无法杜绝您的用户信息被非法访问、非法盗取、非法篡改或毁坏，导致您的合法权益受损，请您理解信息网络的上述风险并自愿承担。
            </p>
            <p>
              （五）在我们采取必要合理的安全保护措施后，仍不幸发生信息泄露等安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。难以逐一告知时，我们将会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
            </p>
            <p class="sub-title bold">五、您的权利</p>
            <p>
              按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：
            </p>
            <p>（一）访问您的用户信息</p>
            <p>
              您有权访问您的用户信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：http://tantao.com/talk/user/personal
              可直接查看个人资料。
            </p>
            <p>
              如果您无法通过上述链接访问这些用户信息，您可以随时使用我们的 Web
              表单联系，或发送电子邮件至<span class="bold">tantao@021.com</span
              >。我们将在15天内回复您的访问请求。
            </p>
            <p>
              对于您在使用我们的产品或服务过程中产生的其他用户信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电子邮件至<span
                class="bold"
                >tantao@021.com</span
              >。
            </p>
            <p class="sub-title bold">（二）更正您的用户信息</p>
            <p>
              当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的用户信息”中罗列的方式提出更正申请。
              如果您无法通过上述链接更正这些用户信息，您可以随时使用我们的 Web
              表单联系，或发送电子邮件至<span class="bold">tantao@021.com</span
              >。我们将在15天内回复您的更正请求。
            </p>
            <p class="sub-title bold">
              （三）删除您的用户信息
            </p>
            <p>
              在以下情形中，您可以向我们提出删除用户信息的请求：
            </p>
            <p>
              1、如果我们处理用户信息的行为违反法律法规；
            </p>
            <p>2、如果我们收集、使用您的用户信息，却未征得您的同意；</p>
            <p>3、如果我们处理用户信息的行为违反了与您的约定；</p>
            <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
            <p>
              5、如果我们不再为您提供产品或服务。
              我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。
            </p>
            <p class="sub-title bold">（四）改变您授权同意的范围</p>
            <p>
              每个业务功能需要一些基本的用户信息才能得以完成（见本政策“第一部分”）。对于用户信息的收集和使用，您可以随时给予或收回您的授权同意。您可以通过以下方式自行操作：<a
                href="http://tantao.com/talk/user/personal"
                target="_blank"
                >http://tantao.com/talk/user/personal</a
              >
              进入用户中心个人资料页面更改用户信息。
            </p>
            <p class="bold">
              当您撤销同意后，我们将不再处理相应的用户信息。同时也请您注意，您撤销授权同意可能会导致某些后果，例如我们可能无法继续为您提供相应的服务或特定的功能，但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。
            </p>
            <p class="sub-title">
              （五）约束信息系统自动决策
            </p>
            <p>
              在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。
            </p>
            <p class="sub-title">
              （六）响应您的上述请求
            </p>
            <p>
              为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
            </p>
            <p>
              我们将在15天内作出答复。如您不满意，还可以通过以下途径投诉：tantao.com对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：
            </p>
            <p>1、与用户信息控制者履行法律法规规定的义务相关的；</p>
            <p>2、与国家安全、国防安全直接相关的；</p>
            <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p>4、与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
            <p>
              5、用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；
            </p>
            <p>
              6、出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p>
              7、响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的；
            </p>
            <p>8、涉及商业秘密的。</p>
            <p class="sub-title bold">六、我们如何处理儿童的个人信息</p>
            <p>
              我们非常重视儿童个人信息的保护，我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的用户账户。尽管当地法律和习俗对儿童的定义不同，但我们将不满
              14 周岁的任何人均视为儿童。
            </p>
            <p>
              对于经父母或监护人同意而收集儿童用户信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下储存、使用或公开披露此信息，否则我们会设法尽快删除相关数据。
            </p>
            <p>
              鉴于现有技术和业务模式的限制，我们很难主动识别儿童的个人信息，如果您发现我们在不知情的情况下或在未事先获得可证实的监护人同意的情况下收集了儿童的个人信息，您可以及时联系我们，我们将在发现后设法及时删除，如果我们自己发现前述情形的，我们也会及时删除，法律要求我们保留的除外。
            </p>
            <p class="sub-title bold">
              七、您的用户信息如何储存以及如何在全球范围转移
            </p>
            <p>
              原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。我们只会在本政策所述目的和用途所需的期限内和法律法规规定的最短期限内保留您的用户信息，超出上述保留期间后，我们会根据适用法律法规的要求删除您的用户信息或匿名化处理。法律法规另有规定，或出于公共利益、科学历史研究等的目的，或您的另行授权同意的除外，我们可能需要较长时间保留相关数据。
            </p>
            <p>
              由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的用户信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。
            </p>
            <p>
              此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的用户信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移用户信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
            </p>
            <p class="sub-title bold">
              八、本政策如何更新
            </p>
            <p>
              我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
              对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：
            </p>
            <p>
              1、我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
            </p>
            <p>
              2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
            </p>
            <p>3、用户信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4、您参与用户信息处理方面的权利及其行使方式发生重大变化；</p>
            <p>
              5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
            </p>
            <p>
              6、用户信息安全影响评估报告表明存在高风险时。
              我们还会将本政策的旧版本存档，供您查阅。
            </p>
            <p class="sub-title bold">
              九、如何联系我们
            </p>
            <p>
              如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：
              电子邮件：<span>tantao@021.com</span>
            </p>
            <p>
              一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的15个工作内予以回复。如您不满意我们的回复，还可以向消费者权益保护部门投诉或向有管辖权的法院提起诉讼。
            </p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { scrollTo } from '@/util/index.js';
// import { isHresolution } from '@/util/index.js';
import logoPath from '../../assets/image/logo-2.png';
// import logoSmPath from '../../assets/image/logo-s.png';
export default {
  name: 'AboutUs',
  data() {
    return {
      tab: 'left',
      type: 'us',
      cardname: '',
      logo: logoPath,
    };
  },
  created() {
    const { type } = this.$route.query;
    if (type) this.type = type;
    this.cardname = this.type;
    // const isH = isHresolution();
    // if (!isH) this.logo = logoSmPath;
  },
  mounted() {},
  methods: {
    seePrivacy() {
      this.$router.push({ path: '/talk/about', query: { type: 'privacy' } });
    },
    seeUser() {
      this.$router.push({ path: '/talk/about', query: { type: 'user' } });
    },
    clickTabs(e) {
      const id = e.$el.id;
      if (id == 'pane-user') {
        if (this.type !== 'user') {
          this.$router.push({ path: '/talk/about', query: { type: 'user' } });
          this.type = 'user';
        }
      } else if (id == 'pane-privacy') {
        if (this.type !== 'privacy') {
          this.$router.push({
            path: '/talk/about',
            query: { type: 'privacy' },
          });
          this.type = 'privacy';
        }
      } else {
        if (this.type !== 'us') {
          this.$router.push({ path: '/talk/about', query: { type: 'us' } });
          this.type = 'us';
        }
      }
      scrollTo(0, 400);
    },
  },
};
</script>

<style lang="scss">
.talk-about {
  font-size: 14px;
  color: #333;
  line-height: 30px;
  .header {
    position: relative;
    width: 100%;
    height: 60px;
    .header-placeholder {
      width: 100%;
      height: 60px;
      background-color: #fff;
      position: fixed;
      z-index: 3;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }
    .header-inner {
      width: 1240px;
      margin: 0 auto;
      height: 60px;
      position: relative;
      .logo {
        position: absolute;
        top: 8px;
        width: 120px;
        height: 44px;
        border: 0;
        margin-left: 15px;
      }
    }
  }
  .about-content {
    width: 1240px;
    margin: 0 auto;
    margin-top: 20px;
    .el-tabs--left {
      .el-tabs__header.is-left {
        width: 150px;
        height: 480px;
      }
      .el-tabs__nav-wrap.is-left {
        margin-right: -1px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
        float: left;
        width: 150px;
        height: 480px;
        background-color: #fff;
        margin-right: 15px;
        position: fixed;
        top: 80px;
        &:after {
          width: 0;
        }
      }
      .el-tabs__item.is-left {
        text-align: center;
      }
      .el-tabs__content {
        min-height: calc(100vh - 80px);
        background-color: #fff;
      }
      .el-tabs__nav-scroll {
        padding-top: 15px;
      }
    }
  }
  .contact-us {
    padding: 20px 30px 0 30px;
    text-align: left;
  }
  .about-content {
    .bold {
      font-weight: bold;
    }
    .need-underline {
      text-decoration: underline;
    }
    .need-indent {
      text-indent: 2rem;
    }
    p.no-indent {
      text-indent: 0;
    }
    .sub-title {
      font-weight: bold;
    }
    .fs16 {
      font-size: 16px;
    }
  }
  .user-privacy {
    padding: 20px 30px 0 30px;
    p {
      padding: 8px 0;
      text-indent: 2rem;
    }
  }
  .user-agreement {
    padding: 20px 30px 0 30px;
    p {
      padding: 8px 0;
      text-indent: 2rem;
    }
  }
}
@media screen and (max-width: 1366px) {
  .talk-about {
    .header .header-inner {
      width: 1000px;
    }
    .about-content {
      width: 1000px;
    }
  }
}
</style>
